import { useState } from 'react'
import { ReactComponent as SuccessInput } from '../../../assets/images/svgs/successInput.svg';

import './CustomTextInput.css'

const CustomTextInput = (props: any) => {

  const [showText, setShowText] = useState<boolean>(false);

  const renderImage = () => {
    let eyeIcon: any
    let statusIcon: any
    let loadingIcon: any
    if (props.password && props.value?.length > 0) {
      if (showText) {
        eyeIcon = (
          <span 
            id={props.label + ' ojo'}
            onClick={() => setShowText(!showText)} 
            className="material-symbols-outlined" 
            style={{ color: props.password, fontSize: 20, cursor: 'pointer'}}
          >visibility</span>
        )
      } else {
        eyeIcon = (
          <span 
            id={props.label + ' ojo'}
            onClick={() => setShowText(!showText)} 
            className="material-symbols-outlined" 
            style={{ color: props.password, fontSize: 20, cursor: 'pointer'}}
          >visibility_off</span>
        )
      }
    }
    if (props.success) {
      statusIcon = <span className="material-symbols-outlined" style={{ color: 'var(--statusSuccessPrimary)', fontSize: 20}}>done</span>
    }
    if (props.error) {
      statusIcon = <span className="material-symbols-outlined" style={{ color: 'var(--statusErrorPrimary)', fontSize: 20}}>error</span>
    }
    if (props.loading) {
      loadingIcon = <span className='customTextInput-container-loader'></span>
    }
    return (
      <div className="customTextInput-container-inputContainer-iconContainer">
        {loadingIcon}
        {eyeIcon}
        <div style={{ width: 5 }}></div>
        {statusIcon}
      </div>
    )
  }

  return (
    <div
      className="customTextInput-container"
      onClick={() => {
        if (props.clickedAction) {
          props.clickedAction()
        }
      }}
      id={props.label}
    >
      {props.label &&
        <div className="customTextInput-container-label">
          <p className="customTextInput-container-label-title bold" style={{ color: props.adminLogin ? 'var(--gray100)' : 'var(--gray500)'}}>{props.label}</p>
          {props.required && <p className="customTextInput-container-label-requiered bold">&nbsp;*</p>}
        </div>
      }
      <div 
        className="customTextInput-container-inputContainer" 
        style={{ 
          backgroundColor: props.adminLogin ? 'transparent' : props.disabled ? 'var(--gray100)' : 'var(--white)'
        }}
      >
        {props.preValue && <p className='customTextInput-container-inputContainer-preValue'>{props.preValue}</p>}
        {props.adminLogin ?
          <input
            id={props.label + ' inputLogin'}
            maxLength={props.maxLength}
            disabled={props.disabled}
            className="customTextInput-container-inputContainer-input customTextInput-container-inputContainer-input-adminLogin"
            placeholder={props.placeholder}
            type={props.password ? !showText ? 'password' : 'default' : props.date ? 'date' : 'default'}
            onChange={(e: any) => props.changeText(e.target.value.trimStart())}
            value={props.value}
            onKeyDown={(e: any) => props.onKeyDown ? props.onKeyDown(e) : {}}
            style={{ color: 'var(--white)', backgroundColor: 'transparent' }}
            onFocus={(event) => event.target.setAttribute('autocomplete', 'off')}
            {...props}
          />
          :
          <input
            id={props.label + ' input'}
            maxLength={props.maxLength}
            disabled={props.disabled}
            className="customTextInput-container-inputContainer-input"
            placeholder={props.placeholder}
            type={props.password ? !showText ? 'password' : 'default' : props.date ? 'date' : 'default'}
            onChange={(e: any) => props.changeText(e.target.value.trimStart())}
            value={props.value}
            onKeyDown={(e: any) => props.onKeyDown ? props.onKeyDown(e) : {}}
            style={{ color: props.disabled ? 'var(--gray400)' : props.color ? 'var(--white)' : 'unset', backgroundColor: 'transparent' }}
            onFocus={(event) => event.target.setAttribute('autocomplete', 'off')}
            {...props}
          />
        }
        {props.verified && <p className="customTextInput-container-inputContainer-verified">Verificado</p>}
        {renderImage()}
      </div>
      <div className="customTextInput-container-line"></div>
      {props.errorMessage && props.error && <p className="customTextInput-container-errorMessage">{props.errorMessage}</p>}
      {props.underText &&
        <div className="customTextInput-container-underText">
          {props.successAlt && <SuccessInput height={12} width={12} />}
          <p className="customTextInput-container-underText-text">&nbsp;{props.underText}</p>
        </div>
      }
      {props.changePasswordClick && <b className="customTextInput-container-cambiar bold medium" onClick={() => props.changePasswordClick()}>Cambiar contraseña</b>}
      {props.forgotPasswordClick && <b className="customTextInput-container-cambiar bold medium" onClick={() => props.forgotPasswordClick()}>¿Olvidaste tu contraseña?</b>}
    </div>
  );
}

export default CustomTextInput;