import React, {useEffect, useState} from 'react'
import CustomTextInput from '../../generalComponents/customTextInput/CustomTextInput';
import MainButton from '../../generalComponents/MainButton/MainButton';
import './CheckOutProfile.css'
import {
    getDataForNewUserArgCarfacil,
    createUserCheckout,
    actionCheckUserExistance
} from '../../../actions'
import {connect} from 'react-redux';
import Moment from 'moment/moment'
import { useMediaQuery } from 'react-responsive'
import DiscountDetailsComponent from '../DiscountDetailsComponent/DiscountDetailsComponent';
import SelectorSearcher from '../../generalComponents/selectorSearcher/SelectorSearcher';
import { useSearchParams } from 'react-router-dom';

let isTabletOrMobile = false;
const CheckOutProfile = (props: any) => {
    isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' })

    //PRESET DATA
    const [presetUserData, setPresetUserData] = useState<any>(null);
    const [loadingPresetData, setLoadingPresetData] = useState<boolean>(false);
    const [notEditable, setNotEditable] = useState<boolean>(false);
    const [preExistingUserId, setPreExistingUserId] = useState<any>(null);

    //Inputs
    const [inicioVigenciaPóliza] = useState<string>(Moment(new Date()).format('DD/MM/YYYY'));
    const [finVigenciaPóliza] = useState<string>(Moment(new Date()).add(1, 'years').format('DD/MM/YYYY'));
    const [inicioVigenciaOperación] = useState<string>(Moment(new Date()).format('DD/MM/YYYY'));
    const [finVigenciaOperación] = useState<string>(Moment(new Date()).add(1, 'years').format('DD/MM/YYYY'));

    const [tipoDocumento, setTipoDocumento] = useState<any>(null);
    const [numeroDocumento, setNumeroDocumento] = useState<string>('');
    const [cuitOne, setCuitOne] = useState<string>('');
    const [cuitTwo, setCuitTwo] = useState<string>('');
    const [cuitThree, setCuitThree] = useState<string>('');

    const [apellido, setApellido] = useState<string>('');
    const [nombre, setNombre] = useState<string>('');

    const [razonSocial, setRazonSocial] = useState<string>('');

    const [sexo, setSexo] = useState<any>(null);
    const [fechaNacimiento, setFechaNacimiento] = useState<string>('');
    const [nacionalidad, setNacionalidad] = useState<any>(null);

    const [tipoTelefono] = useState<any>('PARTICULAR');
    const [celular, setCelular] = useState<string>('');
    const [codigoArea, setCodigoArea] = useState<string>('');
    const [email, setEmail] = useState<string>('');

    const [situaciónIVA, setSituaciónIVA] = useState<any>(null);
    const [estadoCivil, setEstadoCivil] = useState<any>(null);
    
    const [tipoDomicilio] = useState<any>('PARTICULAR');
    const [calle, setCalle] = useState<string>('');
    const [nro, setNro] = useState<string>('');
    const [piso, setPiso] = useState<string>('');
    const [dpto, setDpto] = useState<string>('');
    const [provincia, setProvincia] = useState<any>(null);
    const [localidad, setLocalidad] = useState<any>(null);
    const [codigoPostal, setCodigoPostal] = useState<string>('');

    const [actividad, setActividad] = useState<any>(null);
    const [ingresosBrutos, setIngresosBrutos] = useState<any>(null);


    //Listado de Selectors
    const [tipoDocuList, setTipoDocuList] = useState<any>(null);
    const [tipoTelefonoList] = useState<any>(null);
    const [situacionIVAList, setSituacionIVAList] = useState<any>(null);

    const [sexoList, setSexoList] = useState<any>(null);
    const [nacionalidadList, setNacionalidadList] = useState<any>(null);

    const [estadoCivilList, setEstadoCivilList] = useState<any>(null);

    const [tipoDomiList] = useState<any>(null);

    const [provinciaList] = useState<any>(null);
    const [localidadList] = useState<any>(null);

    const [ingresosBrutosList, setIngresosBrutosList] = useState<any>(null);
    
    //OTROS
    const [admin, setAdmin] = useState<boolean>(false);
    const [longAddress, setLongAddress] = useState<boolean>(false);
    const [searchParams] = useSearchParams();
    const [currentPackage, setCurrentPackage] = useState<any>(null);

    useEffect(() => {
        handleUrlParams();
        props.getDataForNewUserArgCarfacil(props.checkOutData, setProvincia, setLocalidad, setCodigoPostal, setSexoList, setEstadoCivilList, setNacionalidadList, setSituaciónIVA, setSexo, setNumeroDocumento, setCuitTwo, setFechaNacimiento, props.checkOutData?.offer?.packages?.filter((a: any) => a.id === props.checkOutData?.offer?.currentPackage)[0])
        loadHardCodedLists()
        loadPreselectedItems()
    },[])

    useEffect(() => {
        if (currentPackage?.insurer?.id === 40) setActividad({codigo: "EMPL", descripcion: "EMPLEADO"})
        else setActividad({codigo: "130", descripcion: "EMPLEADO"})
    }, [currentPackage])

    useEffect(() => {
        setLongAddress(calle?.length >= 25)
    }, [calle])

    useEffect(() => {
        if(props.checkOutData) {
          setCurrentPackage(props.checkOutData?.offer?.packages?.filter((a: any) => a.id === props.checkOutData?.offer?.currentPackage)[0])
        }
      }, [props.checkOutData])

    useEffect(() => {
        if(numeroDocumento?.length === 8) {
            checkForExistingUser(numeroDocumento)
            setCuitTwo(numeroDocumento)
        }
    }, [numeroDocumento])

    useEffect(() => {
        if(cuitTwo?.length === 8) checkForExistingUser(cuitTwo)
    }, [cuitTwo])

    useEffect(() => {
        if (presetUserData) {
            if(presetUserData?.documentId) {
                const data = {
                    codigo: presetUserData?.documentId,
                    descripcion: tipoDocuList?.filter((a: any) => a.codigo === presetUserData?.documentId)[0]?.descripcion
                }
                setTipoDocumento(data);
            }
            if(presetUserData?.name) setNombre(presetUserData?.name)
            if(presetUserData?.lastName) setApellido(presetUserData?.lastName)
            if(presetUserData?.name || presetUserData?.lastName) setRazonSocial(presetUserData?.name + ' ' + presetUserData?.lastName)

            if(presetUserData?.sexId) setSexo(presetUserData?.sexId)
            if(presetUserData?.birthDate) setFechaNacimiento(presetUserData?.birthDate)
            if(presetUserData?.nacionalityId) setNacionalidad(presetUserData?.nacionalityId)

            if(presetUserData?.phone) setCelular(presetUserData?.phone)
            if(presetUserData?.phonePrefix) setCodigoArea(presetUserData?.phonePrefix)
            if(presetUserData?.username) setEmail(presetUserData?.username)

            if(presetUserData?.iva) setSituaciónIVA(presetUserData?.iva)
            if(presetUserData?.civilStatus) setEstadoCivil(presetUserData?.civilStatus)

            if(presetUserData?.addressStreet) setCalle(presetUserData?.addressStreet)
            if(presetUserData?.addressNumber) setNro(presetUserData?.addressNumber)
            if(presetUserData?.addressFloor) setPiso(presetUserData?.addressFloor)
            if(presetUserData?.dpto) setDpto(presetUserData?.dpto)
            
            if(presetUserData?.rawIncome) setIngresosBrutos(presetUserData?.rawIncome)
        } else {
            setNombre('')
            setApellido('')

            setFechaNacimiento('')
            setEmail('')
            setEstadoCivil("1")

            setCalle('')
            setNro('')
            setPiso('')
            setDpto('')
            
            setIngresosBrutos("0")
            setNacionalidad("Argentina")
        }
    }, [presetUserData])

    const checkForExistingUser = (e: string) => {
        actionCheckUserExistance(e, setPresetUserData, setLoadingPresetData, setNotEditable, setPreExistingUserId, props.checkOutData?.offer?.packages?.filter((a: any) => a.id === props.checkOutData?.offer?.currentPackage)[0])
    }

    const loadPreselectedItems = () => {
        setTipoDocumento({codigo: 'DU', descripcion: 'DNI'})
        setNacionalidad("Argentina")
        setEstadoCivil("1")
        setIngresosBrutos("0")
    }

    const loadHardCodedLists = () => {
        const tipoDocuListHard = [
            {
                codigo: 'LE',
                descripcion: 'LE'
            },
            {
                codigo: 'CI',
                descripcion: 'CI'
            },
            {
                codigo: 'DU',
                descripcion: 'DNI'
            },
            {
                codigo: 'PAS',
                descripcion: 'Pasaporte'
            },
            {
                codigo: 'DU',
                descripcion: 'CUIT'
            }
        ]
        setTipoDocuList(tipoDocuListHard)

        const ingresosBrutosListHard = [
            {
                codigo: "0",
                descripcion: 'No inscripto'
            },
            {
                codigo: 1,
                descripcion: 'Inscripto'
            },
            {
                codigo: 2,
                descripcion: 'Exento'
            },
        ]
        setIngresosBrutosList(ingresosBrutosListHard)

        const situacionIVAListHard = [
            {
                codigo: "0",
                descripcion: 'No Incripto'
            },
            {
                codigo: 1,
                descripcion: 'Resp.Inscripto'
            },
            {
                codigo: 3,
                descripcion: 'Exento'
            },
            {
                codigo: 4,
                descripcion: 'No responsable'
            },
            {
                codigo: 5,
                descripcion: 'Consumidor Final'
            },
            {
                codigo: 6,
                descripcion: 'Cons.Final Pers.No Física'
            },
            {
                codigo: 7,
                descripcion: 'Adherido al Monotributo'
            },
            {
                codigo: 8,
                descripcion: 'Resp.no categorizado'
            },
            {
                codigo: 9,
                descripcion: 'IVA no alcanzado'
            },
            {
                codigo: 10,
                descripcion: 'Responsable Monotributo'
            },
            {
                codigo: 11,
                descripcion: 'Monotributo Social'
            },
            {
                codigo: 12,
                descripcion: 'Pequeño Contrib. Eventual'
            },
            {
                codigo: 13,
                descripcion: 'Pequeño Contrib.Eventual Socia'
            },
            {
                codigo: 14,
                descripcion: "RI Factura 'M'"
            },
            {
                codigo: 15,
                descripcion: "RI FACTURA 'A' C/CBU Informado"
            },
        ]
        setSituacionIVAList(situacionIVAListHard)
    }

    const handleUrlParams = () => {
        const admin: any = searchParams.get("admin");
        if (admin) setAdmin(true)
      };

    const isValidName = (e: string) => {
        if(!(/^\s/.test(e))) {
            return (/^[a-zA-Z\u00C0-\u00FF _]*$/.test(e))
        } return false
    }

    const validateEmail = (e: string) => {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(e).toLowerCase())) {
            return false;
        } return true
    }

    const isValidDate = (e: string) => {
        if(Moment(e, 'YYYY-MM-DD').isValid()) {
            if (Moment(e, 'YYYY-MM-DD').isBefore(Moment(new Date()).add(-18, 'years'))) {
                return true
            }
        }
        return false
    }

    const isDisabled = () => {
        if(showCuit()) {
            return (
                !isValidName(razonSocial) ||
                !tipoDocumento || cuitOne?.length !== 2 || cuitTwo?.length !== 8 || cuitThree?.length !== 1 || !sexo || 
                !isValidDate(fechaNacimiento) || celular === '' || codigoArea === '' || !nacionalidad ||
                !validateEmail(email) || !situaciónIVA || !estadoCivil ||
                calle === '' || nro === '' ||
                !actividad || !ingresosBrutos || longAddress
            )
        }
        return (
            !isValidName(apellido) || !isValidName(nombre) ||
            !tipoDocumento || numeroDocumento?.length !== 8 || cuitOne?.length !== 2 || cuitTwo?.length !== 8 || cuitThree?.length !== 1 || !sexo || 
            !isValidDate(fechaNacimiento) || celular === '' || codigoArea === '' || !nacionalidad ||
            !validateEmail(email) || !situaciónIVA || !estadoCivil ||
            calle === '' || nro === '' ||
            !actividad || !ingresosBrutos || longAddress
        )
    }

    const showCuit = () => {
        return tipoDocumento?.descripcion === 'CUIT';
    }

    return (
        <div className="checkOutProfile-container">
            <div className="checkOutProfile-container-main">
                <div 
                    className="checkOutProfile-container-main-volver"
                    onClick={() => window.history.back()}
                >
                    <span className="material-symbols-outlined" style={{ fontSize: 18 }}>arrow_back_ios_new</span>
                    <b className="checkOutProfile-container-main-volver-text bold medium">Volver</b>
                </div>
                <div className="checkOutProfile-container-main-list">
                    <CustomTextInput
                        label='Inicio de vigencia de póliza'
                        value={inicioVigenciaPóliza}
                        disabled
                    />
                    <CustomTextInput
                        label='Fin de vigencia de póliza'
                        value={finVigenciaPóliza}
                        disabled
                    />
                    <div className="checkOutProfile-container-main-list-invis"></div>
                    <CustomTextInput
                        label='Inicio de vigencia de operación'
                        value={inicioVigenciaOperación}
                        disabled
                    />
                    <CustomTextInput
                        label='Fin de vigencia de operación'
                        value={finVigenciaOperación}
                        disabled
                    />
                    <div className="checkOutProfile-container-main-list-invis"></div>
                </div>
                <h6 className="checkOutProfile-container-main-titulo bold" style={{marginBottom: 24}}>Datos del cliente</h6>
                <div className="checkOutProfile-container-main-list">
                    <div className="checkOutProfile-container-main-list-item-selectorWrapper">
                        <p className="checkOutProfile-container-main-list-item-selectorWrapper-title bold">Tipo de documento&nbsp;<ul>*</ul></p>
                        <SelectorSearcher
                            widthList={isTabletOrMobile ? '87.1%' : '31.4%'}
                            id='Selector Tipo de documento'
                            list={tipoDocuList}
                            onValueSelected={(a: any) => setTipoDocumento(a)}
                            selectedItem={tipoDocuList && tipoDocumento ? tipoDocuList?.filter((a: any) => a.descripcion == tipoDocumento.descripcion)[0]?.descripcion : ''}
                            placeholder='Seleccioná tu Tipo de documento'
                            deleteSelectedItem={() => {
                                setTipoDocumento(null)
                                setNumeroDocumento('')
                                setCuitOne('')
                                setCuitTwo('')
                                setCuitThree('')
                            }}
                            disabled={!tipoDocuList}
                        /> 
                    </div>
                    {!showCuit() && <CustomTextInput
                        label='Número'
                        required
                        changeText={(e: string) => setNumeroDocumento(e.replace(/[^0-9]+/g, ''))}
                        value={numeroDocumento}
                        success={numeroDocumento.length === 8}
                        placeholder='Ingresá sólo números sin puntos'
                        error={numeroDocumento.length !== 8 && numeroDocumento !== ''}
                        maxLength={8}
                        disabled={!tipoDocumento}
                        loading={loadingPresetData}
                    />}
                    <div className="checkOutProfile-container-main-list-item-specialInput">
                        <div className="checkOutProfile-container-main-list-item-specialInput-label">
                            <p className="checkOutProfile-container-main-list-item-specialInput-label-text bold">{showCuit() ? 'CUIT' : 'CUIL'}</p>
                            <p className="checkOutProfile-container-main-list-item-specialInput-label-requiered">&nbsp;*</p>
                        </div>
                        <div className="checkOutProfile-container-main-list-item-specialInput-main">
                            <input
                                className="checkOutProfile-container-main-list-item-specialInput-main-input"
                                style={{ width: '15%' }}
                                maxLength={2}
                                onChange={(e) => setCuitOne(e.target.value.trimStart().replace(/[^0-9]+/g, ''))}
                                value={cuitOne}
                                placeholder='00'
                            />
                            <b className="checkOutProfile-container-main-list-item-specialInput-main-line light large">-</b>
                            <input
                                className="checkOutProfile-container-main-list-item-specialInput-main-input"
                                style={{ width: '70%' }}
                                maxLength={8}
                                onChange={(e) => setCuitTwo(e.target.value.trimStart().replace(/[^0-9]+/g, ''))}
                                value={cuitTwo}
                                placeholder='00000000'
                            />
                            {loadingPresetData && <span className='checkOutProfile-container-main-list-item-specialInput-main-loader'></span>}
                            <b className="checkOutProfile-container-main-list-item-specialInput-main-line light large">-</b>
                            <input
                                className="checkOutProfile-container-main-list-item-specialInput-main-input"
                                style={{ width: '15%' }}
                                maxLength={1}
                                onChange={(e) => setCuitThree(e.target.value.trimStart().replace(/[^0-9]+/g, ''))}
                                value={cuitThree}
                                placeholder='0'
                            />
                            <div className="checkOutProfile-container-main-list-item-specialInput-main-status">
                                {cuitOne.length === 2 && cuitTwo.length === 8 && cuitThree.length === 1 &&
                                    <span className="material-symbols-outlined" style={{ color: 'var(--statusSuccessPrimary)', fontSize: 20}}>done</span>
                                }
                                {cuitOne.length === 8 && (cuitTwo.length !== 2 || cuitThree.length !== 1) &&
                                    <span className="material-symbols-outlined" style={{ color: 'var(--statusErrorPrimary)', fontSize: 20}}>error</span>
                                }
                            </div>
                        </div>
                    </div>
                    {showCuit() && <div className="checkOutProfile-container-main-list-invis"></div>}
                    {showCuit()  ?
                        <div className="checkOutProfile-container-main-list-double">
                            <CustomTextInput
                                label='Razon Social'
                                required
                                changeText={(e: string) => setRazonSocial(e)}
                                value={razonSocial}
                                disabled={!tipoDocumento || presetUserData?.name}
                                success={razonSocial !== ''}
                            />
                        </div>
                        :
                        <>
                            <CustomTextInput
                                label='Apellido'
                                required
                                changeText={(e: string) => setApellido(e)}
                                value={apellido}
                                disabled={!tipoDocumento || presetUserData?.lastName}
                                error={!isValidName(apellido)}
                                success={apellido !== '' ? isValidName(apellido) : false}
                            />
                            <CustomTextInput
                                label='Nombre'
                                required
                                changeText={(e: string) => setNombre(e)}
                                value={nombre}
                                disabled={!tipoDocumento || presetUserData?.name}
                                error={!isValidName(nombre)}
                                success={nombre !== '' ? isValidName(nombre) : false}
                            />
                            <div className="checkOutProfile-container-main-list-invis"></div>
                            <div className="checkOutProfile-container-main-list-double">
                                <CustomTextInput
                                    label='Nombre completo'
                                    value={nombre + ' ' + apellido}
                                    disabled
                                />
                            </div>
                        </>
                    }
                    <div className="checkOutProfile-container-main-list-invis"></div>
                    <div className="checkOutProfile-container-main-list-item-selectorWrapper">
                        <p className="checkOutProfile-container-main-list-item-selectorWrapper-title bold">Sexo&nbsp;<ul>*</ul></p>
                        <SelectorSearcher
                            widthList={isTabletOrMobile ? '87.1%' : '31.4%'}
                            id='Selector Sexo'
                            list={sexoList}
                            onValueSelected={(a: any) => setSexo(a.codigo)}
                            selectedItem={sexoList && sexo ? sexoList?.filter((a: any) => a.codigo == sexo)[0]?.descripcion : ''}
                            placeholder='Seleccioná tu Sexo'
                            deleteSelectedItem={() => setSexo(null)}
                            disabled={!sexoList || presetUserData?.sexId}
                        /> 
                    </div>
                    <CustomTextInput
                        label='Fecha de nacimiento'
                        required
                        changeText={(e: string) => setFechaNacimiento(e)}
                        value={fechaNacimiento}
                        error={fechaNacimiento.length === 0 ? false : !isValidDate(fechaNacimiento)}
                        errorMessage='Fecha inválida'
                        success={isValidDate(fechaNacimiento)}
                        maxLength={10}
                        placeholder="DD/MM/AAAA"
                        type="date"
                        max='2999-12-31'
                        disabled={presetUserData?.birthDate}
                    />
                    <div className="checkOutProfile-container-main-list-item-selectorWrapper">
                        <p className="checkOutProfile-container-main-list-item-selectorWrapper-title bold">Nacionalidad&nbsp;<ul>*</ul></p>
                        <SelectorSearcher
                            widthList={isTabletOrMobile ? '87.1%' : '31.4%'}
                            id='Selector Nacionalidad'
                            list={nacionalidadList}
                            onValueSelected={(a: any) => setNacionalidad(a.descripcion)}
                            selectedItem={nacionalidadList && nacionalidad ? nacionalidadList?.filter((a: any) => a.descripcion == nacionalidad)[0]?.descripcion : ''}
                            placeholder='Seleccioná tu nacionalidad'
                            deleteSelectedItem={() => setNacionalidad(null)}
                            disabled={!nacionalidadList}
                        />
                    </div>
                    <div className="checkOutProfile-container-main-list-item-selectorWrapper">
                        <p className="checkOutProfile-container-main-list-item-selectorWrapper-title bold">Tipo de Teléfono&nbsp;<ul>*</ul></p>
                        <SelectorSearcher
                            widthList={isTabletOrMobile ? '87.1%' : '31.4%'} 
                            id='Selector Tipo de Teléfono' 
                            list={tipoTelefonoList}
                            selectedItem={tipoTelefono} 
                            placeholder='Seleccioná tu Tipo de Teléfono' 
                            disabled
                        />
                    </div>
                    <div className="checkOutProfile-container-main-list-item-customInput">
                        <div className="checkOutProfile-container-main-list-item-customInput-label">
                            <p className="checkOutProfile-container-main-list-item-customInput-label-text bold">Teléfono celular</p>
                            <p className="checkOutProfile-container-main-list-item-customInput-label-requiered bold">&nbsp;*</p>
                        </div>
                        <div className="checkOutProfile-container-main-list-item-customInput-inputContainer">
                            <div className="checkOutProfile-container-main-list-item-customInput-inputContainer-inputWrapper" style={{ width: '25%' }}>
                                <b className="checkOutProfile-container-main-list-item-customInput-inputContainer-inputWrapper-prefix regular large">0</b>
                                <input
                                    className="checkOutProfile-container-main-list-item-customInput-inputContainer-inputWrapper-input"
                                    maxLength={5}
                                    onChange={(e) => setCodigoArea(e.target.value.trimStart().replace(/[^0-9]+/g, ''))}
                                    value={codigoArea}
                                />
                                {codigoArea?.length >= 1 && <span className="material-symbols-outlined" style={{ color: 'var(--statusSuccessPrimary)', fontSize: 20}}>done</span >}
                            </div>
                            <div className="checkOutProfile-container-main-list-item-customInput-inputContainer-inputWrapper" style={{ width: '65%' }}>
                                <b className="checkOutProfile-container-main-list-item-customInput-inputContainer-inputWrapper-prefix regular large">15</b>
                                <input
                                    className="checkOutProfile-container-main-list-item-customInput-inputContainer-inputWrapper-input"
                                    maxLength={8}
                                    onChange={(e) => setCelular(e.target.value.trimStart().replace(/[^0-9]+/g, ''))}
                                    value={celular}
                                />
                                {celular?.length > 0 && celular?.length < 6 ? 
                                    <span className="material-symbols-outlined" style={{ color: 'var(--statusErrorPrimary)', fontSize: 20}}>error</span>
                                    : celular?.length >= 6 ?
                                    <span className="material-symbols-outlined" style={{ color: 'var(--statusSuccessPrimary)', fontSize: 20}}>done</span>
                                    : null
                                }
                            </div>
                        </div>
                    </div>
                    <CustomTextInput
                        label='E-mail'
                        required
                        value={email}
                        changeText={(e: string) => setEmail(e)}
                        success={validateEmail(email)}
                        error={email.length > 0 ? !validateEmail(email) : false}
                        errorMessage='E-mail inválido'
                        placeholder='nombre@ejemplo.com'
                        disabled={props.userAccessToken && !admin || presetUserData?.username}
                    />
                    <div className="checkOutProfile-container-main-list-item-selectorWrapper">
                        <p className="checkOutProfile-container-main-list-item-selectorWrapper-title bold">Situación ante el IVA&nbsp;<ul>*</ul></p>
                        <SelectorSearcher
                            widthList={isTabletOrMobile ? '87.1%' : '31.4%'}
                            id='Selector Situación ante el IVA'
                            list={situacionIVAList}
                            onValueSelected={(a: any) => setSituaciónIVA(a.codigo)}
                            selectedItem={situacionIVAList && situaciónIVA ? situacionIVAList?.filter((a: any) => a.codigo == situaciónIVA)[0]?.descripcion : ''}
                            placeholder='Seleccioná tu Situación ante el IVA'
                            deleteSelectedItem={() => setSituaciónIVA(null)}
                            disabled={!situacionIVAList}
                        /> 
                    </div>
                    <div className="checkOutProfile-container-main-list-item-selectorWrapper">
                        <p className="checkOutProfile-container-main-list-item-selectorWrapper-title bold">Estado civil&nbsp;<ul>*</ul></p>
                        <SelectorSearcher
                            widthList={isTabletOrMobile ? '87.1%' : '31.4%'}
                            id='Selector Estado civil'
                            list={estadoCivilList}
                            onValueSelected={(a: any) => setEstadoCivil(a.codigo)}
                            selectedItem={estadoCivilList && estadoCivil ? estadoCivilList?.filter((a: any) => a.codigo == estadoCivil)[0]?.descripcion : ''}
                            placeholder='Seleccioná tu Estado civil'
                            deleteSelectedItem={() => setEstadoCivil(null)}
                            disabled={!estadoCivilList || presetUserData?.civilStatus}
                        /> 
                    </div>
                    <div className="checkOutProfile-container-main-list-invis"></div>
                </div>
                <h6 className="checkOutProfile-container-main-titulo bold" style={{marginBottom: 24}}>Domicilio</h6>
                <div className="checkOutProfile-container-main-list">
                    <div className="checkOutProfile-container-main-list-item-selectorWrapper">
                        <p className="checkOutProfile-container-main-list-item-selectorWrapper-title bold">Tipo de domicilio&nbsp;<ul>*</ul></p>
                        <SelectorSearcher
                            widthList={isTabletOrMobile ? '87.1%' : '31.4%'}
                            id='Selector Tipo de domicilio'
                            list={tipoDomiList}
                            selectedItem={tipoDomicilio}
                            placeholder='Seleccioná tu Tipo de domicilio'
                            disabled
                        /> 
                    </div>
                    <div className="checkOutProfile-container-main-list-addressWrapp">
                        <div className="checkOutProfile-container-main-list-addressWrapp-inputs">
                            <CustomTextInput
                                label='Calle'
                                required
                                value={calle}
                                success={calle.length > 0}
                                error={longAddress}
                                changeText={(e: string) => setCalle(e)}
                                disabled={presetUserData?.addressStreet}
                            />
                            <div className="checkOutProfile-container-main-list-list">
                                <CustomTextInput
                                    label='Número'
                                    changeText={(e: string) => setNro(e.replace(/[^0-9]+/g, ''))}
                                    value={nro}
                                    required
                                    success={nro !== ''}
                                    disabled={presetUserData?.addressNumber}
                                />
                                <CustomTextInput
                                    label='Piso'
                                    changeText={(e: string) => setPiso(e)}
                                    value={piso}
                                    success={piso !== ''}
                                    disabled={presetUserData?.addressFloor}
                                />
                                <CustomTextInput
                                    label='Dpto.'
                                    changeText={(e: string) => setDpto(e)}
                                    value={dpto}
                                    success={dpto !== ''}
                                    disabled={presetUserData?.dpto}
                                />
                            </div>
                        </div>
                        {longAddress && <p className="checkOutProfile-container-main-list-addressWrapp-error">La calle debe tener menos de 25 caracteres</p>}
                    </div>
                    <div className="checkOutProfile-container-main-list-item-selectorWrapper">
                        <p className="checkOutProfile-container-main-list-item-selectorWrapper-title bold">Provincia</p>
                        <SelectorSearcher
                            widthList={isTabletOrMobile ? '87.1%' : '31.4%'}
                            id='Selector Provincia'
                            list={provinciaList}
                            selectedItem={provincia}
                            placeholder='Seleccioná tu Provincia'
                            disabled
                        /> 
                    </div>
                    <div className="checkOutProfile-container-main-list-item-selectorWrapper">
                        <p className="checkOutProfile-container-main-list-item-selectorWrapper-title bold">Localidad</p>
                        <SelectorSearcher
                            widthList={isTabletOrMobile ? '87.1%' : '31.4%'}
                            id='Selector Localidad'
                            list={localidadList}
                            selectedItem={localidad?.descripcion}
                            placeholder='Seleccioná tu Localidad'
                            disabled
                        /> 
                    </div>
                    <CustomTextInput
                        label='Código postal'
                        value={codigoPostal}
                        disabled
                    />
                </div>
                <h6 className="checkOutProfile-container-main-titulo bold" style={{marginBottom: 24}}>Actividad</h6>
                <div className="checkOutProfile-container-main-list">
                    <div className="checkOutProfile-container-main-list-item-selectorWrapper">
                        <p className="checkOutProfile-container-main-list-item-selectorWrapper-title bold">Actividad&nbsp;<ul>*</ul></p>
                        <SelectorSearcher
                            widthList={isTabletOrMobile ? '87.1%' : '31.4%'}
                            id='Selector Actividad'
                            list={[]}
                            selectedItem={actividad?.descripcion}
                            placeholder='Seleccioná tu Actividad'
                            disabled
                        /> 
                    </div>
                    <div className="checkOutProfile-container-main-list-item-selectorWrapper">
                        <p className="checkOutProfile-container-main-list-item-selectorWrapper-title bold">Ingresos brutos&nbsp;<ul>*</ul></p>
                        <SelectorSearcher
                            widthList={isTabletOrMobile ? '87.1%' : '31.4%'}
                            id='Selector Ingresos brutos'
                            list={ingresosBrutosList}
                            onValueSelected={(a: any) => setIngresosBrutos(a.codigo)}
                            selectedItem={ingresosBrutosList && ingresosBrutos ? ingresosBrutosList?.filter((a: any) => a.codigo == ingresosBrutos)[0]?.descripcion : ''}
                            placeholder='Seleccioná tu Ingresos brutos'
                            deleteSelectedItem={() => setIngresosBrutos(null)}
                            disabled={!ingresosBrutosList || presetUserData?.rawIncome}
                        /> 
                    </div>
                    <div className="checkOutProfile-container-main-list-invis"></div>
                </div>
            </div>
            <div className="checkOutProfile-container-endWrapper">
                <div className="checkOutProfile-container-endWrapper-main">
                    <DiscountDetailsComponent/>
                    <MainButton
                        id='Continuar Boton' 
                        fill
                        color='var(--primary500)'
                        disabled={isDisabled()}
                        text='CONTINUAR'
                        onButtonPressed={() => {
                            const dateGoodFormat = Moment(fechaNacimiento, 'YYYY-MM-DD').format('YYYY-MM-DD')
                            const length = razonSocial?.length;
                            const middle = Math.floor(length / 2);

                            const firstHalf = razonSocial?.slice(0, middle);
                            const secondHalf = razonSocial?.slice(middle);

                            const fastUserData = {
                                "addressFloor": piso,
                                "addressStreet": calle,
                                "addressNumber": nro,
                                "addressType": tipoDomicilio?.codigo,
                                "addressPostalCode": codigoPostal?.toString(),
                                "birthDate": dateGoodFormat,
                                "civilStatus": parseInt(estadoCivil),
                                "documentId": tipoDocumento?.codigo,
                                "documentNumber": numeroDocumento,
                                "cuil": cuitOne + cuitTwo + cuitThree,
                                "dpto": dpto,
                                "lastName": showCuit() ? firstHalf : apellido,
                                "name": showCuit() ? secondHalf : nombre,
                                "razonSocial": showCuit() ? razonSocial : null,
                                "phone": celular,
                                "phonePrefix": codigoArea,
                                "localidadId": localidad?.codigo,
                                "actividadId": actividad?.codigo,
                                "iva": parseInt(situaciónIVA),
                                "ingresosBrutosId": parseInt(ingresosBrutos),
                                "roles":[1],
                                "sexId": sexo,
                                "userName": email,
                                "localidadText": localidad?.descripcion,
                                "provinciaText": provincia,
                                "tipoPersona": showCuit() ? "JURIDICA" : "FISICA",
                                "nacionalityId": nacionalidad
                            }
                            props.createUserCheckout(fastUserData, props.checkOutData?.id, notEditable, preExistingUserId, currentPackage)
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state: any) => {
  return {
    userAccessToken: state.general.userAccessToken
  }; 
};

export default connect(mapStateToProps, {
    getDataForNewUserArgCarfacil,
    createUserCheckout
})(CheckOutProfile);