import React, {useState, useRef, useEffect} from 'react' 
import './SelectorSearcher.css'

const SelectorSearcher = (props: any) => {

  const [openList, setOpenList] = useState<Boolean>(false)
  const [completeList, setCompleteList] = useState<any>(props.list)
  const [textSearch, setTextSearch] = useState<any>('')
  const [preSelectedItemIndex, setPreSelectedItemIndex] = useState<number>(-1)

  const mainRef: any = useRef()

  useEffect(() => {
    function handleClickOutside(event: any) {
        if (mainRef.current && !mainRef.current.contains(event.target)) {
          setOpenList(false)
        }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
        document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [mainRef]);

  useEffect(() => {
    if(props.focus) {
      setOpenList(true)
      filterByText('')
    }
  }, [props.focus])

  useEffect(() => {
    if(!openList) setTextSearch('')
  }, [openList])

  const handleItemSelected = (a: any) => {
    setOpenList(false)
    props.onValueSelected(a)
    setPreSelectedItemIndex(-1)
  }

  const filterByText = (e: String) => {
    setTextSearch(e)
    let auxShow = props.list?.filter((a: any) => (props.cp ? a.descripcion + ' (' + a.codigoPostal + ')' : a.label ? a.label : a.descripcion ? a.descripcion : a.name)?.toLowerCase()?.includes(e?.toLowerCase()))
    setCompleteList(auxShow)
  }

  return (
    <div ref={mainRef} className="selectorSearcher-container" >
      <div 
        id={props.id}
        className="selectorSearcher-container-selector" 
        onClick={() => {
          if(!props.selectedItem && !props.disabled) {
            setOpenList(true)
            filterByText('')
          }
        }}
        style={{
          borderBottomLeftRadius: openList ? 0 : 25,
          borderBottomRightRadius: openList ? 0 : 25,
          backgroundColor: props.disabled ? 'var(--gray100)' : props.selectedItem ? 'var(--primary200)' : 'var(--gray50)',
          border: props.shake ? '1.5px solid var(--statusErrorPrimary)' : props.disabled ? '1.5px solid var(--gray300)' : '1.5px solid var(--primary500)'
        }}
      >
        {props.selectedItem ? 
          <b 
            className="selectorSearcher-container-selector-value regular medium"
            style={{ color: props.disabled ? 'var(--gray300)' : 'var(--primary700)' }}
          >
            {props.selectedItem?.toUpperCase()}
          </b>
          : props.noSearch ? 
            <b 
              className="selectorSearcher-container-selector-placeholder regular medium"
              style={{ color: props.shake ? 'var(--statusErrorPrimary)' : 'var(--secondary500)' }}
              onClick={() => {
                setOpenList(true)
                filterByText('')
              }}
            >{props.placeholder}</b>
            :
            <input 
              id="Selector"
              value={textSearch}
              disabled={props.disabled} 
              className={props.shake ? "selectorSearcher-container-selector-inputShake" : "selectorSearcher-container-selector-input"}
              placeholder={props.placeholder}
              onChange={(e) => filterByText(e.target.value)}
              onKeyDown={(e: any) => {
                if(e.keyCode === 40 && completeList?.length - 1 !== preSelectedItemIndex) { // arrow down
                  setPreSelectedItemIndex(preSelectedItemIndex + 1)
                } else if (e.keyCode === 38 && preSelectedItemIndex >= 0) { // arrow up
                  setPreSelectedItemIndex(preSelectedItemIndex - 1)
                } else if (e.keyCode === 13 && preSelectedItemIndex >= 0) {
                  handleItemSelected(completeList[preSelectedItemIndex])
                }
              }}
              onFocus={(event) => {
                event.target.setAttribute('autocomplete', 'off');
                setOpenList(true)
                filterByText('')
              }}
              onBlur={() => setOpenList(false)}
            />
        }
        {props.selectedItem ? 
          <div 
            id='Delete item'
            className="selectorSearcher-container-selector-lupa"
            style={{
              borderBottomRightRadius: openList ? 0 : 25,
              cursor: 'pointer'
            }}
            onClick={() => {
              if(!props.disabled) {
                setOpenList(true)
                props.deleteSelectedItem()
                filterByText('')
              }
            }}
          >
            <span className="material-symbols-outlined" style={{ fontSize: 24, color: props.disabled ? 'var(--gray400)' : 'var(--primary700)' }}>close</span>
          </div>
          : props.noSearch ? 
            <div 
              className="selectorSearcher-container-selector-lupa"
              style={{
                borderBottomRightRadius: openList ? 0 : 25,
                transform: openList ? 'rotate(180deg)' : 'rotate(0deg)',
                cursor: 'pointer'
              }}
            >
              <span className="material-symbols-outlined" style={{ fontSize: 24, color: props.shake ? 'var(--statusErrorPrimary)' : 'var(--primary700)' }}>keyboard_arrow_down</span>
            </div>
            :
            <div 
              className="selectorSearcher-container-selector-lupa"
              style={{borderBottomRightRadius: openList ? 0 : 25}}
            >
              <span className="material-symbols-outlined" style={{ fontSize: 24, color: props.shake ? 'var(--statusErrorPrimary)' : 'var(--secondary500)' }}>search</span>
            </div>
        }
      </div>
      <div 
        className="selectorSearcher-container-list"
        style={{
          maxHeight: openList ? 250 : 0,
          width: document.getElementById(props.id)?.offsetWidth
        }}
      >
        {completeList?.map((a: any, index: any) => (
          <b
            id={a.label + ' ' + a.subTitle + ' item'}
            key={index}
            className="selectorSearcher-container-list-listItemText regular large"
            onClick={() => handleItemSelected(a)}
          >
            {props.cp ? a.descripcion?.toUpperCase() + ' (' + a.codigoPostal + ')' : a.label ? a.label?.toUpperCase() : a.descripcion ? a.descripcion?.toUpperCase() : a.name?.toUpperCase()}
          </b>
        ))}
      </div>
    </div>
  );
} 

export default SelectorSearcher;