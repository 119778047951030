import React, { useEffect, useState } from "react"

import FormFieldDate from "./FormFields/FormFieldDate/FormFieldDate"
import FormFieldGeo from "./FormFields/FormFieldGeo/FormFieldGeo"
import FormFieldInfo from "./FormFields/FormFieldInfo/FormFieldInfo"
import FormFieldLabel from "./FormFields/FormFieldLabel/FormFieldLabel"
import FormFieldOk from "./FormFields/FormFieldOk/FormFieldOk"
import FormFieldPhoto from "./FormFields/FormFieldPhoto/FormFieldPhoto"
import FormFieldHour from "./FormFields/FormFieldHour/FormFieldHour"
import FormFieldText from "./FormFields/FormFieldText/FormFieldText"

import FormFieldImei from "./FormFields/FormFieldImei/FormFieldImei"
import FormFieldDefault from "./FormFields/FormFieldDefault/FormFieldDefault"
import FormFieldSelector from "./FormFields/FormFieldSelector/FormFieldSelector"
import FormFieldSelectorSearcher from "./FormFields/FormFieldSelectorSearcher/FormFieldSelectorSearcher"
import FormFieldNumber from "./FormFields/FormFieldNumber/FormFieldNumber"
import FormFieldSlider from "./FormFields/FormFieldSlider/FormFieldSlider"
import FormFieldImageOption from "./FormFields/FormFieldImageOption/FormFieldImageOption"
import FormFieldList from "./FormFields/FormFieldList/FormFieldList"
import FormFieldCarLicence from "./FormFields/FormFieldCarLicence/FormFieldCarLicence"
import FormFieldPersonChile from "./FormFields/FormFieldPersonChile/FormFieldPersonChile"
import FormFieldOption from "./FormFields/FormFieldOption/FormFieldOption"
import FormFieldQuestionnaire from "./FormFields/FormFieldQuestionnaire/FormFieldQuestionnaire"
import FormFieldBonificacionesCarfacil from "./FormFields/FormFieldBonificacionesCarfacil/FormFieldBonificacionesCarfacil"


const GetGlobalForm = (props: any) => {

  const [data, setData] = useState<any>(null)
  const [type, setType] = useState<any>(undefined)

  useEffect(() => {
    setData(props.item)
    setType(props.item?.input?.type)
  }, [props.item])

  const handleAnswerUpdate = (data: any, id: string, currentAnswer: any) => {
    let res = {...currentAnswer};
    res[id] = data
    if(props.parent === 'CheckOut') props.setLocalAnswers(res);
    else props.setLocalAnswers(data);
  }
  
  const handleAnswerUpdateSelector = (data: any, next: any) => {
    next(data)
    props.setLocalAnswers(data);
  }

  const formStyle: any = {
      'label': { component: FormFieldLabel },
      'ok': { component: FormFieldOk },
      'option': { component: FormFieldOption },
      'info': { component: FormFieldInfo },
      'file': { component: FormFieldPhoto },
      'camera': { component: FormFieldPhoto },
      'photo': { component: FormFieldPhoto },
      'geoposition': { component: FormFieldGeo },
      'text': { component: FormFieldText },
      'date': { component: FormFieldDate },
      'hour': { component: FormFieldHour },
      'select': { component: FormFieldSelector },
      'product-list': { component: FormFieldSelectorSearcher },
      'imei': { component: FormFieldImei },
      'number': { component: FormFieldNumber },
      'slider': { component: FormFieldSlider },
      'image-option': { component: FormFieldImageOption },
      'list': { component: FormFieldList },
      'carLicence': { component: FormFieldCarLicence },
      'person_chile': { component: FormFieldPersonChile },
      'questionnaire': { component: FormFieldQuestionnaire },
      'search-offer': { component: FormFieldQuestionnaire },
      "bonificaciones_carfacil": {component: FormFieldBonificacionesCarfacil },
      undefined: { component: FormFieldDefault }
  }

  const FormComponent: any = formStyle[type]?.component;

  return (
    <FormComponent
      key={data?.id}
      parent={props.parent}
      data={data}
      onUpdate={(value: any) => handleAnswerUpdate(value, data.id, props.currentAnswer)}
      onUpdateAlt={(value: any) => handleAnswerUpdateSelector(value, props.next)}
      checkOutData={props.checkOutData}
      next={props.next}
    />
  )

}

export default GetGlobalForm;